<template>
  <div class="page">
    <!--  导航  -->
    <nav-bar PitchOn="开源项目"></nav-bar>
    <!--    项目-->
    <div class="works-info">
      <div class="guide-info">
        <el-breadcrumb>
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>项目</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="works-list">
        <transition-group
            name="more"
            v-bind:css="false"
            v-on:before-enter="beforeEnter"
            v-on:enter="enter">
          <div class="list" v-for="(item,index) in articleList"
               @click="onArticle(item)"
               :data-index="index"
               :key="index">
            <div class="picture">
              <el-image class="pic" :src="item.img"></el-image>
            </div>
            <div class="title">
              <span>{{item.title}}</span>
            </div>
            <div class="desc">
              <span>{{item.describe}}</span>
            </div>
            <div class="level">
              <div class="star">
                <span class="iconfont icon-xingxing"></span>
                <span>1233</span>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
      <el-empty description="无数据" v-show="articleList.length === 0"></el-empty>
      <div class="pagination">
        <el-pagination background layout="prev, pager, next"
                       @current-change="changePage"
                       :page-size="20"
                       :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar/NavBar";
import {getCurrentInstance, onMounted, reactive, toRefs} from "vue";
import {useRouter} from "vue-router";
import {ArticleList} from "../../api/api";
export default {
  name: "OpenSource",
  components: {NavBar},
  setup(){
    let { proxy } = getCurrentInstance();
    const router = useRouter();
    const data = reactive({
      articleList: [],
      parseTime: '',
      // 页码
      page: 1,
      total: 0,
      /**
       * 获取数据
       */
      getData(){
        ArticleList({
          type: 2,
          page: data.page
        }).then(res =>{
          data.articleList = res.data.result;
          data.total = res.data.total;
        })
      },
      /**
       * 页码改变
       * @param page
       */
      changePage(page){
        data.page = page;
        data.getData();
      },
      /**
       * 文章点击
       * @param item
       */
      onArticle(item){
        router.push({path: '/ArticleDetails',query: {article_id: item.article_id,type: 1}})
      },
      beforeEnter (el) {
        el.style.opacity = 0
      },
      enter (el, done) {
        let delay = el.dataset.index * 100
        setTimeout(()=>{
          el.style.transition = 'opacity 0.4s '
          el.style.opacity = 1
          el.style.animation = 'one-in 0.4s infinite'
          el.style['animation-iteration-count'] = 1
          done()
        }, delay)
      }
    })
    onMounted(() => {
      data.parseTime = proxy.util.parseTime;
      data.getData();
    })
    return {
      ...toRefs(data),
    }
  }
}
</script>

<style scoped lang="scss">
@import "OpenSource.scss";
</style>
